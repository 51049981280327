<template>
  <div class="slider colored-slider">
    <div class="slider__slides-block" ref="slider">
      <div class="slider__slides-wrap"
           :style="{left: this.leftPos? this.leftPos: '0'}">
        <div class="slider__slides-item"
             v-for="(slide, i) of slides"
             :key="i"
             :style="{background: slide.bgColor?slide.bgColor:''}"
             :class="[slide.isChecked ? 'active' : '']">
          <img :src="require(`../../assets/img/${slide.imgSlide}`)" :alt="slide.bgColor">
        </div>
      </div>
    </div>
    <div class="slider__nav-block">
      <button class="btn btn__circle"
              v-for="(slide, i) of slides"
              :key="i"
              @click="checkSlide(i)"
              :class="[slide.isChecked ? 'active' : '']">
        <span class="btn__circle-color" :style="{background: slide.bgColor}">
        </span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ColoredSlider",
  props: ['slides'],
  data() {
    return {
      leftPos: -2,
      widthWindow: 100,
      widthSlideBlock: 832
    }
  },
  computed: {
    widthSlider() {
      return this.$refs.slider.offsetWidth
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
    this.leftPos = '-' + this.slides.findIndex(item => item.isChecked) * this.widthSlider + 'px';
    this.updateWidth()
    // this.checkSlide(this.slides.length - 1)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.widthWindow = window.innerWidth;
      if (this.widthWindow < 896) {
        this.widthSlideBlock = 344
      } else if (this.widthWindow < 1090) {
        this.widthSlideBlock = 640
      } else {
        this.widthSlideBlock = 832
      }
    },
    checkSlide(index) {
      this.slides.map((item, i) => i === index ? item.isChecked = true : item.isChecked = false)
      // this.leftPos = index !== 0 ? '-' + this.widthSlideBlock * index + 'px' : '0';
      this.leftPos = index !== 0 ? '-' + this.widthSlider * index + 'px' : '0';
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/var-style";
@import "src/assets/style/buttons";

.slider {
  display: flex;
  flex-direction: column;
  max-width: 832px;
  width: 100%;

  &__slides-block {
    overflow: hidden;
    position: relative;
    border-radius: 4px;
  }

  &__nav-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__slides-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    left: 0;
    transition: left .3s ease-in-out;
  }

  &__slides-item {
    //min-height: 480px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      //width: 560px;
    }
  }
}

@media screen and (max-width: 1090px) {
  .slider__slides-block,
  .slider__slides-item,
  .slider {
    width: 640px;
  }
  .slider {
    margin: 0 auto;
  }
  .slider__slides-item {
    img {
      width: 100%;
      max-width: 344px;
    }
  }
}

@media screen and (max-width: 896px) {
  .slider,
  .slider__slides-block,
  .slider__slides-item {
    max-width: 344px;
    width: 100%;
  }
  .slider__slides-item {
    height: 172px;
    min-height: 172px;

    img {
      width: 100%;
      //max-width: 235px;
    }
  }
}
</style>
