<template>
  <div class="page-item">

    <p class="page-item__title text__head" v-if="content.title">
      {{ content.title }}
    </p>

    <p class="page-item__text text__normal" v-if="content.text && !Array.isArray(content.text)" v-html="content.text"></p>

    <div class="page-item__text text__normal" v-else-if="content.text && Array.isArray(content.text)">
      <p class="page-item__text-item" v-for="text in content.text" :key="text" v-html="text"></p>
    </div>

    <!--если картинка-->
    <template v-if="content.type==='image'">
      <div class="page-item__content">
        <template v-for="(img, idx) in content.images">

          <div :key="img.img" class="page-item__wrapper">
            <div class="page__min-container">
              <div class="page-item__image-wrapper">
                <img
                  class="page-item__image"
                  :src="require(`@/assets/img/${img.img}`)"
                  alt=""
                />
              </div>
            </div>

            <div v-if="img.icons" class="download-block image__download-block" :key="img.img + idx">
              <icon-download
                v-for="icon in img.icons"
                :key="icon.icon"
                :url="icon.url"
                :icon="icon.icon"
              ></icon-download>
            </div>
          </div>
        </template>
      </div>
    </template>

    <!--если слайдер-->
    <template v-if="content.type==='slider'">
      <div class="page-item__content">
        <div class="page-item__wrapper">
          <component :is="content.sliderType" :slides="content.slides"/>

          <div v-if="content.icons" class="download-block slider__download-block">
            <icon-download
              v-for="icon in content.icons"
              :key="icon.icon"
              :url="icon.url"
              :icon="icon.icon"

            ></icon-download>
          </div>
        </div>

      </div>
    </template>

    <!--если компонент-->
    <template v-if="content.type==='component'">
      <div class="page-item__content">
        <div class="page-item__wrapper">
          <component :is="content.component" :data="content.data"/>

          <div v-if="content.icons" class="download-block">
            <icon-download
              v-for="icon in content.icons"
              :key="icon.icon"
              :url="icon.url"
              :icon="icon.icon"

            ></icon-download>
          </div>
        </div>

      </div>
    </template>
  </div>
</template>

<script>
import IconDownload from "../IconDownload/IconDownload"
import ColoredSlider from "../../Sliders/ColoredSlider"
import ImagesSlider from "../../Sliders/ImagesSlider"
import OfficeSlider from "../../Sliders/OfficeSlider"
import LogoDescriptor from "../LogoDescriptor/LogoDescriptor"


export default {
  components: {LogoDescriptor, OfficeSlider, ImagesSlider, IconDownload, ColoredSlider},
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";

.page-item {
  margin-bottom: 96px;

  &__title {
    font-size: 26px;
    //letter-spacing: -0.3px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 32px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    max-width: 688px;
    //letter-spacing: -0.2px;
    letter-spacing: 0;
    margin-bottom: 48px;

    &-item {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  &__image {
    width: 100%;
    border-radius: 4px;

    &-wrapper {
      display: flex;
      align-items: flex-start;
    }
  }
}

.page {
  &__min-container {
    margin-bottom: 0;
  }
}

.download-block {
  display: flex;
}

@media screen and (max-width: 1216px) {
  .download-block {
    display: none;
  }
}

@media screen and (max-width: 896px) {
  .page-item {
    margin-bottom: 79px;

    &__wrapper {
      margin-bottom: 31px;
    }

    &__image {
      border-radius: 2px;
    }
  }
}
</style>
