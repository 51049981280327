<template>
  <a
      :href="url"
      :class="className"
      class="icon-download"
      download
  >
    <img :src="require(`../../../assets/img/icons/${icon}`)" :alt="icon">
  </a>
</template>

<script>
// import Axios from "axios";

export default {
  name: "IconDownload",

  props: {
    url: {
      type: String,
    },
    icon: {
      type: String,
    },
    className: {
      type: String,
    },
  },

  // methods: {
  //   downloadItem(url) {
  //     Axios.get(url, {responseType: 'blob'})
  //         .then(response => {
  //           const blob = new Blob([response.data], {type: 'application/zip'})
  //           const link = document.createElement('a')
  //           link.href = URL.createObjectURL(blob)
  //           link.download = 'logo'
  //           link.click()
  //           URL.revokeObjectURL(link.href)
  //         }).catch(console.error)
  //   },
  // }
}

</script>

<style scoped lang="scss">
@import "src/assets/style/buttons";

.icon-download {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #E4E7EB;
  background: #F2F5F5;
  box-shadow: -5px -5px 16px rgba(253, 255, 255, 0.8), 5px 5px 16px rgba(187, 195, 206, 0.6);
  transition: all .2s ease-in-out;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 48px;
    height: 48px;
  }
}

.icon-download:hover {
  box-shadow: -6px -6px 32px #FDFFFF, 6px 6px 32px rgba(187, 195, 206, 0.8);
  border-radius: 40px;
}

.icon-download:active {
  box-shadow: -2px -2px 8px rgba(253, 255, 255, 0.8), 2px 2px 8px rgba(187, 195, 206, 0.6), inset 0px 0px 24px rgba(228, 231, 235, 0.3);
}
</style>