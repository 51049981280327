<template>
  <div class="logo-descriptor">
    <div class="page__min-container">
      <img
        :src="require(`@/assets/img/${active}`)"
        alt=""
      >
      <div class="buttons">
        <button-toggle
          v-for="item in data"
          :key="item.icon"
          :is-active="item.src === active"
          is-circle
          @click="setActive(item.src)"
        >
          <img :src="require(`@/assets/img/${item.icon}`)" alt="">
        </button-toggle>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonToggle from "../ButtonToggle/ButtonToggle"

export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      active: this.data[0].src
    }
  },
  methods: {
    setActive(src) {
      this.active = src
    }
  },
  components: {ButtonToggle}
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/page-block";

img {
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 37px;

  & button {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>