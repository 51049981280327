<template>
  <div>
    <div class="page-nav__tablet">
      <router-link class="next-page__link" :to="prevLink.url">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        {{ prevLink.text }}
      </router-link>
      <router-link class="next-page__link" :to="nextLink.url">
        {{ nextLink.text }}
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" :to="nextLink.url">
        {{ nextLink.text }}
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BottomNavBlock",
    props: {
      prevLink: {
        type: Object,
        default: null
      },
      nextLink: {
        type: Object,
        default: null
      }
    }
  }
</script>

<style scoped lang="scss">
//@import "src/assets/style/page-block";


.next-page {
  display: none;
  width: 100%;
  background: #fff;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 39px;

  &__link {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-decoration: none;
    color: #697480;
    display: flex;
    align-items: center;

    img {
      margin-left: 20px;
    }
  }
}

.page-nav__tablet {
  width: 100%;
  display: none;
  //background: #fff;
  position: absolute;
  bottom: 290px;
  left: 0;
  justify-content: space-around;

  .next-page__link {
    font-size: 16px;
    line-height: 18px;
    padding: 24px;
    width: calc(50% - 1px);
    justify-content: center;
    background: #fff;
    font-weight: 200;
    font-family: 'suisse_intl', sans-serif;

    img {
      margin: 23px;

      &[alt="prev"] {
        transform: rotate(180deg);
      }
    }
  }
}
@media screen and (max-width: 1456px) {
  .page-nav__tablet {
    display: flex;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block {
    display: none;
  }
}


@media screen and (max-width: 896px) {
  .next-page {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 288px;
    left: 0;
  }

  .page-nav__tablet {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .next-page {
    bottom: 290px;
  }
}
</style>