<template>
  <button class="button" type="button" :class="{active: isActive, circle: isCircle}" @click.prevent="$emit('click')">
    <slot/>
  </button>
</template>
<script>
export default {
  emits: ["click"],
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isCircle: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
.button {
  display: inline-block;
  border: none;
  background: #F2F5F5;
  color: #697480;
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: 0.02em;
  border-radius: 24px;
  //box-shadow: -3px -3px 4px rgba(255, 255, 255, 0.9), 3px 3px 4px rgba(187, 195, 206, 0.4);
  box-shadow: -3px -3px 4px hsla(0deg, 50%, 100%, 90%), 3px 3px 4px rgba(228, 231, 235, 90%);
  padding: 10px 25px;
  transition: all 0.2s;
  &.active {
    transition: all 0.2s;
    color: #004CD1;
    //box-shadow: inset -3px -3px 4px #FFFFFF, inset 3px 3px 4px rgba(187, 195, 206, 0.35);
    //box-shadow: inset -3px -3px 4px #fff,inset 3px 3px 4px #e4e7eb;
    box-shadow: inset -3px -3px 4px #fff, inset 3px 3px 4px #e4e7eb;
  }
  &.circle {
    border-radius: 50%;
    padding: 20px;
  }
  &:hover {
    cursor: pointer;
  }
  &:not(.active):hover {
    color: #004CD1;
    cursor: pointer;
    //box-shadow: -3px -3px 8px rgba(255, 255, 255, 0.7), 3px 3px 8px rgba(187, 195, 206, 0.2), -3px -3px 16px rgba(255, 255, 255, 0.5), 3px 3px 16px rgba(187, 195, 206, 0.4);
    box-shadow: -3px -3px 8px hsla(0deg, 0%, 100%, 80%), 3px 3px 8px rgba(228, 231, 235, 80%), -3px -3px 16px hsla(0deg, 0%, 100%, 40%), 3px 3px 16px rgba(228, 231, 235, 40%);
  }
  &:active {
    color: #004CD1;
    //box-shadow: inset -3px -3px 4px #FFFFFF, inset 3px 3px 4px rgba(187, 195, 206, 0.35);
    box-shadow: inset -3px -3px 4px #fff,inset 3px 3px 4px #e4e7eb;
  }
}
</style>