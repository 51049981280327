<template>
  <div class="slider slider-images">
    <div class="slider__slides-block" ref="slider">
      <div class="slider__slides-wrap"
           v-bind:style="{left: this.leftPos? this.leftPos: '0'}">
        <div class="slider__slides-item"
             v-for="(slide, i) of slides"
             :key="i">
          <img :src="require(`../../assets/img/${slide.imgSlide}`)">
        </div>
      </div>
    </div>
    <div class="slider__nav-block">
      <button class="btn btn__circle btn-prev btn__nav-slider"
              @click="checkSlide(-1)">
        <img src="~assets/img/icons/icon-row.svg" alt="prev">
      </button>
      <button class="btn btn__circle btn-next btn__nav-slider"
              @click="checkSlide(+1)">
        <img src="~assets/img/icons/icon-row.svg" alt="next">
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['slides'],
  data(){
    return{
      leftPos: 0,
      activeIndex: 0,
      widthWindow: 100,
      widthSlideBlock: 832
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth()
  },
  computed: {
    widthSlider() {
      return this.$refs.slider.offsetWidth
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
  methods:{
    updateWidth() {
      this.widthWindow = window.innerWidth;
      // console.log(this.widthWindow)
      if(this.widthWindow <= 896){
        this.widthSlideBlock = 318
        // console.log(this.widthSlideBlock)
      } else if(this.widthWindow <= 1090) {
        this.widthSlideBlock = 640
        // console.log(this.widthSlideBlock)
      } else {
        this.widthSlideBlock = 832
        // console.log(this.widthSlideBlock)
      }
    },
    checkSlide(index){
      switch (index){
        case -1:
          this.activeIndex = this.activeIndex !== 0 ? this.activeIndex + index :  this.slides.length -1;
          this.slides.map((item, i) => i === index ? item.isChecked = true : item.isChecked = false)
          this.leftPos = index !== 0 ? '-'+ this.widthSlider * this.activeIndex + 'px': '0';
          break
        case +1:
          this.activeIndex = this.activeIndex < this.slides.length -1 ? this.activeIndex + index : 0;
          this.slides.map((item, i) => i === index ? item.isChecked = true : item.isChecked = false)
          this.leftPos = index !== 0 ? '-'+ this.widthSlider * this.activeIndex + 'px': '0';
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/var-style";
@import "src/assets/style/buttons";

//.slider{
//  display: flex;
//  flex-direction: column;
//  max-width: 832px;
//  width: 100%;
//
//  &__slides-block{
//    overflow: hidden;
//    position: relative;
//    border-radius: 4px;
//  }
//  &__nav-block{
//    width: 100%;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    margin-top: 24px;
//  }
//  &__slides-wrap{
//    display: flex;
//    flex-direction: row;
//    align-items: center;
//    position: relative;
//    left: 0;
//    transition: left .3s ease-in-out;
//  }
//  &__slides-item{
//    //min-height: 480px;
//    min-width: 100%;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    img{
//      width: 100%;
//    }
//  }
//}
////@media screen and (max-width: 1000px){
////  .slider-logo{
////    width: 832px;
////  }
////}
//
//@media screen and (max-width: 1090px){
//  .slider,
//  .slider__slides-block,
//  .slider__slides-item,
//  .slider {
//    width: 640px;
//  }
//  .slider {
//    margin: 0 auto;
//    &__slides-item{
//      min-height: 480px;
//      img{
//        width: 100%;
//      }
//    }
//    &__nav-block{
//      width: 100%;
//      display: flex;
//      align-items: center;
//      justify-content: center;
//      margin-top: 135px;
//    }
//  }
//}
//@media screen and (max-width: 896px) {
//  .slider,
//  .slider__slides-block,
//  .slider__slides-item,
//  .slider {
//    max-width: 318px;
//    width: 100%;
//  }
//  .slider {
//    &__slides-item {
//      height: fit-content;
//      min-height: 172px;
//      img {
//        width: 100%;
//        max-width: 318px;
//      }
//    }
//    &__nav-block {
//      margin-top: 111px;
//    }
//  }
//}

.slider {
  display: flex;
  flex-direction: column;
  max-width: 832px;
  width: 100%;

  &__slides-block {
    overflow: hidden;
    position: relative;
    border-radius: 4px;
  }

  &__nav-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__slides-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    left: 0;
    transition: left .3s ease-in-out;
  }

  &__slides-item {
    //min-height: 480px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      //width: 560px;
    }
  }
}

@media screen and (max-width: 1090px) {
  .slider__slides-block,
  .slider__slides-item,
  .slider {
    width: 640px;
  }
  .slider {
    margin: 0 auto;
  }
  .slider__slides-item {
    img {
      width: 100%;
      max-width: 344px;
    }
  }
}

@media screen and (max-width: 896px) {
  .slider,
  .slider__slides-block,
  .slider__slides-item {
    max-width: 344px;
    width: 100%;
  }
  .slider__slides-item {
    height: 172px;
    min-height: 172px;

    img {
      width: 100%;
      //max-width: 235px;
    }
  }
}
</style>